import React, { useState, useEffect } from "react";
import arrow from "../../../../../assets/media/arrow.png";
import downArrow from "../../../../../assets/media/down-arrow.png";
import infoIconEmpty from "../../../../../assets/media/infoIconEmpty.svg";
import ChallengeImageDropzone from "../../../../dropzone/ChallengeImageDropzone";
import "../../../../../assets/css/InteractionCreation.css";
import { getCookie } from "../../../auth/tokenUtils";
import { useNavigate, useLocation } from "react-router-dom";
import { isMyTokenExpired } from "../../../auth/tokenUtils";
import instagramSVG from "../../../../../assets/media/instagram.svg";
import youtubeSVG from "../../../../../assets/media/youtube.svg";
import facebookSVG from "../../../../../assets/media/facebook.svg";
import twitterSVG from "../../../../../assets/media/x-twitter.svg";
import tiktokSVG from "../../../../../assets/media/tiktok.svg";
import tick from "../../../../../assets/media/tickInteraction.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { apiUrl, imagesUrl } from "../../../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import noImageToShow from "../../../../../assets/media/placeholder.png";
import PhoneViewJoin from "../../../../phone/phoneViewJoin";
import Loader from "../../../../popup/loader";
import QRCode from "qrcode.react";
import QrOption from "../../../../utils/qrOption";
import QrOptionReadOnly from "../../../../utils/qrOptionReadOnly";
import SingleNumberInput from "../../../../utils/singleNumberInput";
import { Tooltip } from "react-tippy";
import Pagination from "../../../../tables/Pagination";
import CallAPI from "../../../../api/callApi";
import ItemsPerPageButton from "../../../../button/itemsPerPageButton";
import ApprovalPopup from "../../../../popup/approvalPopup";
const ContentCreation = () => {
  const [loaderState, setLoaderState] = useState(false);

  const location = useLocation();
  const { returnedChallenge } = location.state || {};
  const navigate = useNavigate();
  const [
    currentQuestionImageResponseString,
    setCurrentQuestionImageResponseString,
  ] = useState(null);

  const [modifiedActions, setModifiedActions] = useState([]);
  //validations
  const [isNameValid, setIsNameValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [isCoinValid, setIsCoinValid] = useState(true);

  const [inputUrl, setInputUrl] = useState("");
  const [savedInputUrlArray, setSavedInputUrlArray] = useState([]);
  const [urlEditAreaClicked, setUrlEditAreaClicked] = useState(false);
  const [editUrl, setEditUrl] = useState("");
  const [savedUrl, setSavedUrl] = useState("");
  const { t, i18n } = useTranslation("challengeCreation");

 
  const [popupSegment, setPopupSegment] = useState();
  const [popup, setPopup] = useState();

  const [segments, setSegments] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState(false);
  const [segmentPage, setSegmentPage] = useState(0);
  const [segmentTotalPage, setSegmentTotalPage] = useState(false);
  const [segmentPerPage, setSegmentPerPage] = useState(20);
  const [segmentTotalCount, setSegmentTotalCount] = useState(false);

  const [inputTag, setInputTag] = useState("");
  const [savedTags, setSavedTags] = useState([]);
  const [editingTagIndex, setEditingTagIndex] = useState(null);
  const [tagEditAreaClicked, setTagEditAreaClicked] = useState(false);

  const [inputHashtag, setInputHashtag] = useState("");
  const [savedHashtags, setSavedHashtags] = useState([]);
  const [editingHashtagIndex, setEditingHashtagIndex] = useState(null);
  const [editHashtag, setEditHashtag] = useState("");
  const [hashtagEditAreaClicked, setHashtagEditAreaClicked] = useState(false);
  const [qrChecked, setQrChecked] = useState(false);

  //datas to send
  const [imageResponseString, setImageResponseString] = useState("");
  const [challengeName, setChallengeName] = useState("");
  const [challengeDescription, setChallengeDescription] = useState("");
  const [surveyType, setSurveyType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [coin, setCoin] = useState();
  const [questions, setQuestions] = useState();
  const [likertType, setLikertType] = useState();
  const [socialInteractionUrl, setSocialInteractionUrl] = useState("");
  const [selectedContentTypeId, setSelectedContentTypeId] = useState("");
  const [tagAccount, setTagAccount] = useState(null);
  const [hashtag, setHashtag] = useState(null);
  const [qrString, setQrString] = useState();

  //info datas to send

  const [infoLocation, setInfoLocation] = useState(null);
  const [infoHashtag, setInfoHashtag] = useState(null);
  const [infoTagAccount, setInfoTagAccount] = useState(null);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const onLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const [inputMusic, setInputMusic] = useState("");
  const [savedMusic, setSavedMusic] = useState([]);
  const [editingMusicIndex, setEditingMusicIndex] = useState(null);
  const [editMusic, setEditMusic] = useState("");
  const [musicEditAreaClicked, setMusicEditAreaClicked] = useState(false);

  const [checkedProvider, setCheckedProvider] = useState("");

  const [challengeImage, setChallengeImage] = useState(null);

  useEffect(() => {
    console.log(qrChecked);
    if (qrChecked) {
    }
  }, [qrChecked]);
  // returnedChallenge varsa stateleri setle
  useEffect(() => {
    if (returnedChallenge) {
      const responseData = returnedChallenge.responseData || {};
      fetchCommands();
      setImageResponseString(responseData.logo || "");
      setSelectedSegment(responseData.segment || "");
      setChallengeName(responseData.name || "");
      setChallengeDescription(responseData.description || "");
      setSurveyType(responseData.surveyType || "");
      setStartDate(
        moment.utc(responseData.startDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setEndDate(
        moment.utc(responseData.endDate).format("YYYY-MM-DDTHH:mm:ss") || ""
      );
      setCoin(responseData.prize || null);
      setTagAccount(
        responseData.contentCreationActionResponseList.find(
          (item) => item.type === "TAG_ACCOUNT"
        )?.value || ""
      );
      setHashtag(
        responseData.contentCreationActionResponseList.find(
          (item) => item.type === "HASHTAG"
        )?.value || ""
      );
      setInfoHashtag(
        responseData.contentCreationActionResponseList.find(
          (item) => item.type === "HASHTAG"
        )?.infoValue || ""
      );
      setInfoTagAccount(
        responseData.contentCreationActionResponseList.find(
          (item) => item.type === "TAG_ACCOUNT"
        )?.infoValue || ""
      );
      if (responseData.qr) {
        setQrChecked(true);
        setQrString(responseData.qr);
      }

      setCheckedProvider(responseData.socialProvider);

      const matchingActionIndex = modifiedActions.findIndex(
        (action) =>
          action[2] === responseData.socialProviderContentCreationType.id
      );

      if (matchingActionIndex !== -1) {
        setModifiedActions((prevActions) => {
          const newActions = [...prevActions];
          newActions[matchingActionIndex][1] = true;
          return newActions;
        });
      }
    }
  }, [returnedChallenge]);

  const challengeNameChange = (e) => {
    setChallengeName(e.target.value);
  };

  const handleInputChange = (value, setter) => {
    setter(value);
  };

  const challengeDescriptionChange = (e) => {
    setChallengeDescription(e.target.value);
  };

  const infoSections = [
    {
      title: "Tag",
      value: tagAccount,
      infoValue: infoTagAccount,
    },
    {
      title: "Hashtag",
      value: hashtag,
      infoValue: infoHashtag,
    },
  ];


  const handleSegmentPopup = (segment) => {
    setPopupSegment(segment);
    setPopup(true);
  };

  const handleFetchSegments = async () => {
    setLoaderState(true);
    const segmentResponse = await CallAPI({
      method: "GET", // HTTP isteği türü
      endPoint:
        apiUrl +
        `/api/v1/brand-admin/brand/segment?page=${segmentPage}&size=${segmentPerPage}`, // API URL'si
      body: undefined, // İstek gövdesi
      successMessage: undefined, // Başarılı mesaj (isteğe bağlı)
      errorMessage: t("errorMessage"), // Hata mesajı
      navigate: navigate, // Yönlendirme fonksiyonu
      t: t, // Çeviri işlevi
    });
    setLoaderState(false);
    console.log(segmentResponse);
    setSegmentTotalPage(segmentResponse.data.pageMetadata.totalPage);
    setSegmentPage(segmentResponse.data.pageMetadata.page);
    setSegmentTotalCount(segmentResponse.data.pageMetadata.count);
    console.log(segmentResponse.data.responseData);

    // allSotiers'ı en başa ekleyelim
    const updatedSegments = [
      {
        id: 0,
        name: t("allSotiers"),
        description: t("segmentForAllUsers"),
        expressionText: `=${t("allSotiers")}`,
      },
      ...segmentResponse.data.responseData,
    ];

    setSegments(updatedSegments);
  };



  const onChangeInputAction = (value, actionType) => {
    switch (actionType) {
      case 0:
        setTagAccount(value);
        break;
      case 1:
        setHashtag(value);
        break;

      default:
    }
  };
  const onChangeInputActionInfo = (value, actionType) => {
    switch (actionType) {
      case 0:
        setInfoTagAccount(value);
        break;
      case 1:
        setInfoHashtag(value);
        break;

      default:
    }
  };

  useEffect(() => {
    const token = getCookie("access_token");

    if (!token) {
      console.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }
    handleFetchSegments();
  }, []);
  useEffect(() => {
    handleFetchSegments();
  }, [segmentPage, segmentPerPage]);
  useEffect(() => {
    const token = getCookie("access_token");
    if (!token) {
      console.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }
  }, [getCookie("access_token")]);

  const startDateChange = (e) => {
    const startDate = e.target.value;
    if (startDate === "") {
      toast.error(t("error.missingStartDate"));
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedStartDate = new Date(startDate).toISOString().slice(0, 19);
    const selectedStartDate = new Date(startDate);
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30);
    if (selectedStartDate < now) {
      toast.error(t("error.invalidStartDate"));
      e.target.value = "";
      handleInputChange("", setStartDate, "startDate");
      return;
    } else {
      setStartDate(formattedStartDate);
      handleInputChange(formattedStartDate, setStartDate, "startDate");
    }
  };

  const endDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate === "") {
      toast.error(t("error.missingEndDate"));
      handleInputChange("", setEndDate, "endDate");
      return;
    }

    // Tarihten "Z" harfini kaldırın
    const formattedEndDate = new Date(newEndDate).toISOString().slice(0, 19);
    if (new Date(formattedEndDate) < new Date(startDate)) {
      toast.error(t("error.invalidEndDate"));
      e.target.value = "";
      handleInputChange("", setEndDate, "endDate");
    } else {
      setEndDate(formattedEndDate);
      handleInputChange(formattedEndDate, setEndDate, "endDate");
    }
  };

  const [
    isChallengeStartingTypeOnlineChecked,
    setIsChallengeStartingTypeOnlineChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeStoreChecked,
    setIsChallengeStartingTypeStoreChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateChecked,
    setisChallengeStartingTypeOnlineDeliveryDateChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineShoppingDateChecked,
    setIsChallengeStartingTypeOnlineShoppingDateChecked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay0Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay1Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay2Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked,
  ] = useState(false);
  const [
    isChallengeStartingTypeOnlineDeliveryDateDelay3Checked,
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked,
  ] = useState(false);

  const challengeStartingTypeOnlineChecked = () => {
    setIsChallengeStartingTypeOnlineChecked(true);
    setIsChallengeStartingTypeStoreChecked(false);

    showOnlineShoppingSection();
    hideStoreShoppingSection();
  };
  const challengeStartingTypeStoreChecked = () => {
    setIsChallengeStartingTypeOnlineChecked(false);
    setIsChallengeStartingTypeStoreChecked(true);

    resetOnlineDeliveryOptions();
    hideOnlineShoppingSection();
    showStoreShoppingSection();
  };

  const showOnlineShoppingSection = () => {
    const onlineShoppingSection = document.querySelector(
      ".challenge-starting-instructions-delivery-date-checkbox-container"
    );
    const onlineShoppingSection2 = document.querySelector(
      ".challenge-strating-instructions-delivery-date-delay-selection-container"
    );
    const onlineShoppingSection3 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-checkbox-container"
    );
    const onlineShoppingSection4 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-info-row"
    );
    const onlineShoppingSection5 = document.querySelector(
      ".challenge-starting-instructions-advice-row"
    );

    if (onlineShoppingSection) {
      onlineShoppingSection.style.display = "flex";
      onlineShoppingSection2.style.display = "block";
      onlineShoppingSection3.style.display = "flex";
      onlineShoppingSection4.style.display = "flex";
      onlineShoppingSection5.style.display = "flex";
    }
  };

  const hideStoreShoppingSection = () => {
    const storeShoppingSection = document.querySelector(
      ".challenge-starting-instructions-store-info-row"
    );
    if (storeShoppingSection) {
      storeShoppingSection.style.display = "none";
    }
  };
  const showStoreShoppingSection = () => {
    const storeShoppingSection = document.querySelector(
      ".challenge-starting-instructions-store-info-row"
    );
    if (storeShoppingSection) {
      storeShoppingSection.style.display = "flex";
    }
  };

  const resetOnlineDeliveryOptions = () => {
    setIsChallengeStartingTypeOnlineShoppingDateChecked(false);
    setisChallengeStartingTypeOnlineDeliveryDateChecked(false);

    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateChecked = () => {
    setisChallengeStartingTypeOnlineDeliveryDateChecked(true);
    setIsChallengeStartingTypeOnlineShoppingDateChecked(false);
  };
  const challengeStartingTypeOnlineShoppingDateChecked = () => {
    setIsChallengeStartingTypeOnlineShoppingDateChecked(true);
    setisChallengeStartingTypeOnlineDeliveryDateChecked(false);

    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay0Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay1Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay2Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(true);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(false);
  };
  const challengeStartingTypeOnlineDeliveryDateDelay3Checked = () => {
    setIsChallengeStartingTypeOnlineDeliveryDateDelay0Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay1Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay2Checked(false);
    setIsChallengeStartingTypeOnlineDeliveryDateDelay3Checked(true);
  };
  const hideOnlineShoppingSection = () => {
    const onlineShoppingSection = document.querySelector(
      ".challenge-starting-instructions-delivery-date-checkbox-container"
    );
    const onlineShoppingSection2 = document.querySelector(
      ".challenge-strating-instructions-delivery-date-delay-selection-container"
    );
    const onlineShoppingSection3 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-checkbox-container"
    );
    const onlineShoppingSection4 = document.querySelector(
      ".challenge-starting-instructions-shopping-date-info-row"
    );
    const onlineShoppingSection5 = document.querySelector(
      ".challenge-starting-instructions-advice-row"
    );

    if (onlineShoppingSection) {
      onlineShoppingSection.style.display = "none";
      onlineShoppingSection2.style.display = "none";
      onlineShoppingSection3.style.display = "none";
      onlineShoppingSection4.style.display = "none";
      onlineShoppingSection5.style.display = "none";
    }
  };

  const coinChange = (e) => {
    const value = e.target.value;
    const intValue = parseInt(value, 10); // Parse the input as an integer

    if (!isNaN(intValue) && intValue >= 1 && intValue <= 100000) {
      // Geçerli bir tamsayı ve 1 ile 100000 arasında mı kontrol edin
      setCoin(intValue); // Geçerli değeri kullanabilirsiniz
    } else {
      toast(t("error.invalidCoinValue"));
      e.target.value = "";
    }
  };

  const onPostSelectedContentType = () => {
    if (returnedChallenge) {
      return returnedChallenge.responseData.socialProviderContentCreationType
        .id;
    }
    // Convert commandsData to an array
    const commandsArray = Object.entries(commandsData).flatMap(
      ([key, value]) => value
    );

    // Find the element where checked is true
    const checkedElement = commandsArray.find((item) => item.checked);

    // Get the ID of the checked element
    const checkedId = checkedElement ? checkedElement.id : null;

    // Do something with the checked ID
    return checkedId;

    // Perform any other actions if needed
  };

  useEffect(() => {
    if (!qrChecked) setQrString();
  }, [qrChecked]);

  const handleInteractionUpload = async (previewBool) => {
    if (!checkedProvider) {
      toast.error(t("error.missingSocialMediaType"));
      return;
    }
    if (!(hashtag || tagAccount)) {
      toast.error(t("error.missingTagOrHashtag"));
      return;
    } // Önce hashtag kontrolü
    if (!hashtag) {
      setInfoHashtag(null);
    }
    // Sonra tagAccount kontrolü
    if (!tagAccount) {
      setInfoTagAccount(null);
    }
    if (!returnedChallenge) {
      if (modifiedActions.every((action) => !action[1])) {
        toast.error(t("error.missingInteractionAction"));
        return;
      }
    }

    const token = getCookie("access_token");
    if (!token) {
      console.error(t("error.missingAccessToken"));
      navigate("/sign-in-brand");
      return;
    }
    if (isMyTokenExpired(token)) {
      toast.error(t("error.sessionExpired"));
      navigate("/sign-in-brand");
    }

    if (!imageResponseString) {
      setIsImageValid(false);
      toast.error(t("error.missingChallengeImage"));
      return;
    }
    setIsImageValid(true);
    if (!challengeName) {
      setIsNameValid(false);
      toast.error(t("error.missingChallengeName"));
      return;
    } else if (challengeName.length < 10) {
      setIsNameValid(false);
      toast.error(t("error.shortChallengeName"));
      return;
    }
    setIsNameValid(true);

    if (!challengeDescription) {
      setIsDescriptionValid(false);
      toast.error(t("error.missingChallengeDescription"));
      return;
    } else if (challengeDescription.length < 20) {
      setIsDescriptionValid(false);
      toast.error(t("error.shortChallengeDescription"));
      setIsDescriptionValid(true);
      return;
    }
    if (!startDate) {
      setIsStartDateValid(false);
      toast.error(t("error.missingStartDate"));
      return;
    }
    setIsStartDateValid(true);

    if (!endDate) {
      setIsEndDateValid(false);
      toast.error(t("error.missingEndDate"));
      return;
    }
    setIsEndDateValid(true);

    const startTime = new Date(startDate);
    const endTime = new Date(endDate);
    const timeDifference = endTime - startTime;

    if (timeDifference < 60000) {
      toast.error(t("error.invalidTimeRange"));
      return;
    }

    if (coin === undefined) {
      setIsCoinValid(false);
      toast.error(t("error.missingCoinValue"));
      return;
    }

    if (coin < 1 || coin > 100000) {
      setIsCoinValid(false);
      toast.error(t("error.invalidCoinValue"));
      return;
    }

    setIsCoinValid(true);

    // Continue with creating the challenge

    // Get the access token from the cookie (You should have a function to get cookies)
    const authToken = getCookie("access_token");
    if (!authToken) {
      console.error("Access token is missing.");
      navigate("/sign-in-brand");
      return;
    }

    const readyToSendCheckedProvider = checkedProvider.toUpperCase();
    // Define the API endpoint URL

    const challengeData = {
      logo: imageResponseString,
      description: challengeDescription,
      name: challengeName,
      socialChallengeType: "CONTENT_CREATION",
      socialProvider: readyToSendCheckedProvider,
      startDate: startDate,
      endDate: endDate,
      prize: coin,
      tagAccount: !tagAccount ? null : tagAccount,
      hashtag: !hashtag ? null : hashtag,

      infoHashtag: infoHashtag,
      infoTagAccount: infoTagAccount,
      segment: selectedSegment,
      music: null,

      socialProviderContentCreationTypeId: onPostSelectedContentType(),
    };
    if (qrString !== undefined) {
      challengeData.qr = qrString;
    }

    // Kullanıcının oturum açmış olduğunu varsayalım ve bu oturum açma bilgisini auth token olarak alalım

    // Axios ile POST isteği gönderme
    if (returnedChallenge) {
      setLoaderState(true);

      const endPoint =
        apiUrl +
        "/api/v1/brand-admin/brand/challenge/social/content/" +
        returnedChallenge.responseData.id;
      axios
        .put(endPoint, challengeData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.updateChallenge")); // Başarı mesajı göster

            if (previewBool) {
              navigate("/brand/challenges/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/challenges");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          console.log(error);
          toast.error(
            t("error.updateChallenge") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    } else {
      const endPoint =
        apiUrl + "/api/v1/brand-admin/brand/challenge/social/content";
      axios
        .post(endPoint, challengeData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderState(false);

            toast.success(t("success.ChallengeCreation")); // Başarı mesajı göster

            if (previewBool) {
              navigate("/brand/challenges/preview", {
                state: { returnedApiData: response.data },
              });
            } else {
              navigate("/brand/challenges");
            }

            // Başarılı sonuçları işlemek için burada kod ekleyebilirsiniz.
          }
        })
        .catch((error) => {
          setLoaderState(false);

          console.log(error);
          toast.error(
            t("error.ChallengeCreation") +
              "(" +
              error.response.data.metadata.message +
              ")"
          );
          // Hataları işleyin (örneğin, kullanıcıya bir hata mesajı gösterin)
        });
    }
  };

  const handleImageUpload = async (file, isChallengeImage) => {
    setLoaderState(true);

    try {
      // Get the access token from the cookie
      const authToken = getCookie("access_token");

      // Create a FormData object to send the file
      const formData = new FormData();

      // Convert Blob URL to Blob data
      const response = await fetch(file);
      const blobData = await response.blob();

      // Append Blob data to FormData
      formData.append("file", blobData);

      // Determine the URL based on whether it's a challenge or a question image
      const uploadUrl = isChallengeImage
        ? apiUrl + "/api/v1/brand-admin/brand/challenge/logo"
        : apiUrl + "/api/v1/brand-admin/brand/challenge/logo"; // Replace with the actual URL

      // Determine the state and toast message based on whether it's a challenge or a question image
      const stateToUpdate = isChallengeImage
        ? setImageResponseString
        : setCurrentQuestionImageResponseString;
      const successMessage = isChallengeImage
        ? t("success.uploadChallengeImage")
        : t("success.uploadQuestionImage");

      // Use Axios to send the POST request to upload the file
      const uploadResponse = await axios.post(uploadUrl, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      stateToUpdate(uploadResponse.data.responseData);

      // İşlem başarıyla tamamlandığında state'i güncelleyebilirsiniz
      setChallengeImage(file);
      setLoaderState(false);

      toast.success(successMessage);
    } catch (error) {
      setLoaderState(false);

      console.error("Dosya yüklenirken hata oluştu:", error);
      setChallengeImage("");
      toast.error(t("error.fileUpload") + "(" + error.message + ")");
    }
  };

  const [commandsData, setCommandsData] = useState([]);
  useEffect(() => {
    if (!returnedChallenge) fetchCommands();
  }, []);
  useEffect(() => {
    if (tagAccount === "") {
      setInfoTagAccount(null);
    }
    if (hashtag === "") {
      setInfoHashtag(null);
    }
  }, [hashtag, tagAccount]);

  const transformResponse = (data) => {
    const transformedData = {};

    data.socialProviderContentTypeResponseList.forEach((item) => {
      const { id, contentCreationType, socialProvider } = item;

      if (!transformedData[socialProvider]) {
        transformedData[socialProvider] = [];
      }

      transformedData[socialProvider].push({
        id,
        contentCreationType,
        socialProvider,
        checked: false, // Add a checked field with default value false
      });
    });

    return transformedData;
  };

  // Axios ile GET isteği gönderme
  const fetchCommands = () => {
    setLoaderState(true);

    const authToken = getCookie("access_token");
    axios
      .get(
        apiUrl + "/api/v1/brand-admin/brand/challenge/social/content/command",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const responseData = transformResponse(response.data.responseData);
          setCommandsData(responseData);

          const interactionChallengeActions =
            response.data.responseData.socialProviderContentTypeResponseList;
          // Iterate over the numeric properties of the object
          const actionsArray = Object.keys(interactionChallengeActions).map(
            (key) => ({
              id: interactionChallengeActions[key].id,
              action: interactionChallengeActions[key].contentCreationType,
            })
          );

          if (returnedChallenge) {
            const matchingActionIndex = modifiedActions.findIndex(
              (action) =>
                action[2] ===
                returnedChallenge.responseData.socialProviderContentCreationType
                  .id
            );

            if (matchingActionIndex !== -1) {
              setModifiedActions((prevActions) => {
                const newActions = [...prevActions];
                newActions[matchingActionIndex][1] = true; // Fix the typo here (matchingActionIFndex -> matchingActionIndex)
                return newActions;
              });
            }
          } else {
            setModifiedActions(
              actionsArray.map((item) => [
                item.action.charAt(0).toUpperCase() +
                  item.action.slice(1).toLowerCase(),
                false,
                item.id,
              ])
            );

            setCommandsData(responseData);
          }
          // Modify the actions and set them in the state
          setLoaderState(false);
        }
      })
      .catch((error) => {
        setLoaderState(false);

        console.log(error);
        toast.error(t("error.fetchChallengeData") + "(" + error.message + ")");
      });
  };

  const handleCheckboxChange = (socialProvider) => {
    setCheckedProvider(socialProvider);
    setTagAccount(null);
    setHashtag(null);
    setInfoHashtag(null);
    setInfoLocation(null);
    setInfoTagAccount(null);
  };

  const getProviderIcon = (socialProvider) => {
    switch (socialProvider) {
      case "INSTAGRAM":
        return instagramSVG;
      case "FACEBOOK":
        return facebookSVG;
      case "YOUTUBE":
        return youtubeSVG;
      case "TIKTOK":
        return tiktokSVG;
      case "TWITTER":
        return twitterSVG;
      default:
        return;
    }
  };

  const handleActionCheckboxChange = async (changedItem) => {
    if (returnedChallenge !== undefined) {
      return;
    } else {
      const updatedActions = modifiedActions.map((item) => {
        if (item[2] === changedItem.id) {
          item[1] = true;
        } else {
          item[1] = false;
        }

        return [item[0], item[1], item[2]];
      });

      setModifiedActions(updatedActions);

      // Convert commandsData to an array
      const commandsArray = Object.entries(commandsData).flatMap(
        ([key, value]) => value
      );

      // Update commandsData
      const updatedCommandsData = commandsArray.map((item) => ({
        ...item,
        checked: item.id === changedItem.id ? true : false,
      }));

      // Convert the array back to an object
      const updatedCommandsObject = updatedCommandsData.reduce((acc, item) => {
        if (!acc[item.socialProvider]) {
          acc[item.socialProvider] = [];
        }
        acc[item.socialProvider].push(item);
        return acc;
      }, {});

      setCommandsData(updatedCommandsObject);

      // Perform any other actions based on the updated data if needed
      setModifiedActions(updatedActions);
    }
  };

  const ListProviders = (socialProviderData) => {
    if (socialProviderData && socialProviderData.length > 0) {
      return (
        <div className="interaction-creation-social-media-interaction-type-container">
          <div className="interaction-creation-social-media-interaction-type-row">
            <div
              className={`interaction-type-container ${
                returnedChallenge ? "challenge-creation-disabled-container" : ""
              }`}
            >
              <div className="interaction-type-title-container">
                <div className="challenge-creation-input-container-header-title">
                  {t("contentType")}:<span>*</span>
                </div>
                <div className="challenge-creation-input-container-header-subtitle">
                  {t("selectContentFeature")}
                </div>
              </div>
              <div className="flex-row flex-wrap">
                {socialProviderData.map((item) => (
                  <div className="flex-1" key={item.id}>
                    <div className="interaction-creation-social-media-interaction-type-input-container">
                      <div className="interaction-creation-social-media-interaction-type-input-container-left">
                        <label className="interaction-creation-social-media-interaction-type-input-checkbox">
                          <input
                            type="checkbox"
                            checked={
                              returnedChallenge &&
                              returnedChallenge.responseData
                                .socialProviderContentCreationType.id ===
                                item.id
                                ? true
                                : item.checked
                            }
                            onChange={() => handleActionCheckboxChange(item)}
                            readOnly={returnedChallenge !== undefined}
                          />
                          <span className="interaction-creation-social-media-interaction-type-input-checkmark">
                            <img src={tick} alt="tick" />
                          </span>
                        </label>
                      </div>
                      <div className="interaction-creation-social-media-interaction-type-input-container-right">
                        <div className="challenge-creation-input-container-header">
                          <div className="challenge-creation-input-container-header-title">
                            {item.contentCreationType}
                          </div>
                          <div className="challenge-creation-input-container-header-subtitle">
                            {item.contentCreationType} {t("theInteraction")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {infoSections.map((section, index) => (
              <div key={index} className="interaction-creation-url-container">
                <hr style={{ marginBottom: "20px" }} />
                <div className="flex-row flex-wrap">
                  <div className="interaction-creation-social-media-interaction-input-wrapper flex-column flex-1 interaction-action-min-width">
                    <div className="interaction-creation-input-container-header">
                      <div className="challenge-creation-input-container-header-title">
                        {section.title}:<span>*</span>
                      </div>
                      <label className="challenge-creation-input-container-header-subtitle">
                        {t("onlyOneAllowed")} : {section.title}
                      </label>
                    </div>
                    <div className="interaction-creation-url-wrapper">
                      <input
                        className={`interaction-creation-social-media-interaction-step-input content-creation-description ${
                          !(section.value === null || section.value === "")
                            ? "allowed-border"
                            : ""
                        }`}
                        type="text"
                        placeholder={section.title + " " + t("add")}
                        value={section.value}
                        maxLength={40}
                        onChange={(e) =>
                          onChangeInputAction(e.target.value, index)
                        }
                      />
                      <label className="challenge-title-info-container">
                        <img
                          className="challenge-title-info-icon"
                          src={infoIconEmpty}
                          alt="icon"
                        />
                        {t("max")} 40 {t("character")}
                      </label>
                    </div>
                  </div>
                  {section.value && (
                    <div className="interaction-creation-social-media-interaction-input-wrapper flex-1 interaction-action-min-width">
                      <div className="interaction-creation-input-container-header">
                        <div className="challenge-creation-input-container-header-title">
                          {t("description")}:
                        </div>
                        <label className="challenge-creation-input-container-header-subtitle">
                          {section.title} {t("writeDescription")}
                        </label>
                      </div>
                      <div className="interaction-creation-url-wrapper">
                        <input
                          type="text"
                          className={`interaction-creation-social-media-interaction-step-input content-creation-description ${
                            !(
                              section.infoValue === null ||
                              section.infoValue === ""
                            )
                              ? "allowed-border"
                              : ""
                          }`}
                          placeholder={"Açıklama (isteğe bağlı)"} // placeholder'ı düzeltilen satır
                          maxLength={80}
                          value={section.infoValue}
                          onChange={(e) =>
                            onChangeInputActionInfo(e.target.value, index)
                          }
                        />
                        <span className="challenge-title-info-container">
                          <img
                            className="challenge-title-info-icon"
                            src={infoIconEmpty}
                            alt="icon"
                          />
                          {t("max")} 80 {t("character")}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="dashboard-container dashboard-row">
      {loaderState && <Loader />}
      {popup && (
        <ApprovalPopup
          questionText={t("areYouSureToDeleteSegment")}
          cancelText={t("cancel")}
          approveText={t("delete")}
          onClose={() => {
            setPopup(false);
          }}
          isDeleteApprove={false}
          onCancelButtonClick={() => setPopup(false)}
          approvalObject={popupSegment}
        />
      )}
      <div className="challenge-creation-content-container">
        {/* KAMPANYA GORSELI */}
        <div className="challenge-creation-challenge-img-container">
          <div className="challenge-creation-challenge-segment-title">
            {t("challengeImage")}
          </div>

          <ChallengeImageDropzone
            onImageUpload={(file) => handleImageUpload(file, true)}
            defaultImageUrl={
              returnedChallenge
                ? imagesUrl + "/" + returnedChallenge.responseData.logo
                : ""
            }
            style={{ border: isImageValid ? "" : "2px solid red" }}
          />
          <label>{t("allowedFileTypes")} png, jpg</label>
        </div>
        {/* KAMPANYA ADI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeName")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("enterChallengeTitle")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-title-container">
              <input
                className="challenge-title"
                placeholder={t("enterChallengeName")}
                defaultValue={challengeName}
                maxLength="60"
                onBlur={challengeNameChange}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setChallengeName,
                    "challengeName"
                  )
                }
                style={{ border: isNameValid ? "" : "2px solid red" }}
              />
              <div className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">
                  {t("min")} 10, {t("max")} 60 {t("character")}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* KAMPANYA ACIKLAMA */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeDescription")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("enterChallengeDescription")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-title-container">
              <textarea
                className="challenge-title"
                placeholder={t("enterChallengeDescriptionPlaceholder")}
                maxLength="511"
                defaultValue={challengeDescription}
                onBlur={challengeDescriptionChange}
                onChange={(e) =>
                  handleInputChange(
                    e.target.value,
                    setChallengeDescription,
                    "challengeDescription"
                  )
                }
                style={{ border: isDescriptionValid ? "" : "2px solid red" }}
              />
              <div className="challenge-title-info-container">
                <img
                  className="challenge-title-info-icon"
                  src={infoIconEmpty}
                  alt="info:"
                />
                <div className="challenge-title-info-text">
                  {t("min")} 20, {t("max")} 512 {t("character")}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SEGMENT LISTESI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("SegmentList")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("SegmentListSubtitle")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="segment-list-container">
              <div className="segment-list-inner">
                {segments &&
                  segments?.map((segment, index) => (
                    <div key={index} className="challenge-creation-segment-row">
                      <div
                        className={
                          selectedSegment === segment.id
                            ? "qr-checkbox checked"
                            : "qr-checkbox"
                        }
                        onClick={() => {
                          setSelectedSegment(segment.id);
                        }}
                      >
                        <img src={tick} alt="Tick" />
                      </div>

                      <div className="challenge-creation-segment-content-container">
                        <Tooltip
                          title={t("showDetails")}
                          position="bottom-start"
                          theme="light"
                          size="small"
                          delay="1000"
                        >
                          <div
                            className="challenge-creation-segment-title segment-list-segment-title outline"
                            onClick={() => {
                              handleSegmentPopup(segment);
                              console.log("SEGMENT = ", segment);
                            }}
                          >
                            {segment.name}
                          </div>
                        </Tooltip>
                        <div className="challenge-creation-segment-description">
                          {segment.description}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="segment-list-footer">
              <Pagination
                currentPage={segmentPage + 1 || null}
                totalPages={segmentTotalPage}
                onPageChange={(page) => {
                  setSegmentPage(page - 1);
                }}
              />
              <ItemsPerPageButton
                onSelectItem={(e) => {
                  handleInputChange(e, setSegmentPerPage);
                }}
                title={t("segmentPerPage")}
              />
            </div>
          </div>
        </div>
        {/* SOSYAL MEDYA KANALLARI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("selectSocialMediaChannel")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("createSocialMediaChannel")}{" "}
            </div>
          </div>
          <hr />
          <div className="interaction-creation-input-container-content-wrapper">
            {Object.keys(commandsData).map(
              (socialProvider) =>
                (!returnedChallenge ||
                  returnedChallenge?.responseData?.socialProvider ===
                    socialProvider) && (
                  <div
                    key={socialProvider}
                    id={socialProvider}
                    className="interaction-creation-social-media-row"
                  >
                    <div className="interaction-creation-social-media-header">
                      <div className="interaction-creation-social-media-header-left">
                        {!returnedChallenge && (
                          <div className="survey-type-checkbox-wrapper">
                            <input
                              type="radio"
                              style={{ display: "none" }}
                              id={`radio-provider-${socialProvider}`}
                              name="survey-type"
                              readOnly={returnedChallenge}
                              checked={checkedProvider === socialProvider}
                              value={`${socialProvider}-checked`}
                              onChange={() =>
                                handleCheckboxChange(socialProvider)
                              }
                            />
                            <div
                              className={`survey-type-checkbox${
                                checkedProvider === socialProvider
                                  ? " checked"
                                  : ""
                              }`}
                              onClick={() =>
                                handleCheckboxChange(socialProvider)
                              }
                            ></div>
                          </div>
                        )}
                      </div>
                      <div className="interaction-creation-social-media-header-right">
                        <img
                          className="interaction-creation-social-media-header-right-icon"
                          src={getProviderIcon(socialProvider)}
                          alt="Social icon"
                        />
                        <div className="interaction-creation-social-media-header-right-text-wrapper">
                          <div>{socialProvider}</div>
                          <div className="challenge-creation-input-container-header-subtitle"></div>
                        </div>
                      </div>
                    </div>

                    {checkedProvider === socialProvider &&
                      ListProviders(commandsData[socialProvider])}
                  </div>
                )
            )}
          </div>
        </div>
        {/* ANKET TARIHI */}
        <div className="challenge-creation-input-container">
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeDate")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("setCampaignDateTime")}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-creation-date-column-container">
              <div className="challenge-creation-date-column">
                <div className="challenge-creation-date-title">
                  {t("startDate")}
                  <span>*</span>
                </div>
                <input
                  type="datetime-local"
                  className="challenge-creation-date-picker"
                  defaultValue={
                    returnedChallenge
                      ? moment
                          .utc(returnedChallenge.responseData.startDate)
                          .local()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : startDate
                  }
                  onBlur={startDateChange}
                  style={{ border: isStartDateValid ? "" : "2px solid red" }}
                />
              </div>

              <div className="challenge-creation-date-column">
                <div className="challenge-creation-date-title">
                  {t("endDate")}
                  <span>*</span>
                </div>
                <input
                  type="datetime-local"
                  className="challenge-creation-date-picker"
                  defaultValue={
                    returnedChallenge
                      ? moment
                          .utc(returnedChallenge.responseData.endDate)
                          .local()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      : endDate
                  }
                  onBlur={endDateChange}
                  style={{ border: isEndDateValid ? "" : "2px solid red" }}
                />
              </div>
            </div>

            <div className="challenge-creation-date-description-row">
              <img
                className="challenge-creation-date-description-icon"
                src={infoIconEmpty}
                alt="icon"
              />
              <div className="challenge-creation-date-description">
                {t("setChallengeStartDate")}
              </div>
            </div>
            <div className="challenge-creation-date-description-row">
              <img
                className="challenge-creation-date-description-icon"
                src={infoIconEmpty}
                alt="icon"
              />
              <div className="challenge-creation-date-description">
                {t("noEndDateInfo")}
              </div>
            </div>
          </div>
        </div>
        {/* KAMPANYA BASLAMA TALIMATI */}
        <div
          className="challenge-creation-input-container"
          style={{ display: "none" }}
        >
          <div className="challenge-creation-input-container-header">
            <div className="challenge-creation-input-container-header-title">
              {t("challengeStartInstructions")}
              <span>*</span>:
            </div>
            <div className="challenge-creation-input-container-header-subtitle">
              {t("challengeProcessInstructions")}{" "}
            </div>
          </div>
          <hr />
          <div className="challenge-creation-input-container-content-wrapper">
            <div className="challenge-starting-instructions-column-container">
              <div className="challenge-starting-instructions-column">
                <div className="challenge-starting-instructions-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isChallengeStartingTypeOnlineChecked}
                    onChange={challengeStartingTypeOnlineChecked}
                  />
                  <div
                    className={`challenge-starting-instructions-checkbox${
                      isChallengeStartingTypeOnlineChecked ? " checked" : ""
                    }`}
                    onClick={challengeStartingTypeOnlineChecked}
                  ></div>
                  <div className="challenge-starting-instructions-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="challenge-starting-instructions-checkbox-title"
                    >
                      {t("online")}
                    </label>
                    <div className="challenge-starting-instructions-checkbox-subtitle"></div>
                  </div>
                </div>

                <div className="challenge-starting-instructions-delivery-date-checkbox-container">
                  <div className="challenge-starting-instructions-delivery-date-checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="resimli-anket"
                      name="survey-method"
                      checked={isChallengeStartingTypeOnlineDeliveryDateChecked}
                      onChange={challengeStartingTypeOnlineDeliveryDateChecked}
                    />
                    <div
                      className={`challenge-starting-instructions-delivery-date-checkbox${
                        isChallengeStartingTypeOnlineDeliveryDateChecked
                          ? " checked"
                          : ""
                      }`}
                      onClick={challengeStartingTypeOnlineDeliveryDateChecked}
                    ></div>
                    <div className="challenge-starting-instructions-delivery-date-checkbox-title-wrapper">
                      <label
                        htmlFor="resimli-anket"
                        className="challenge-starting-instructions-delivery-date-checkbox-title"
                      >
                        {t("deliveryDay")}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="challenge-strating-instructions-delivery-date-delay-selection-container">
                  {isChallengeStartingTypeOnlineDeliveryDateChecked && (
                    <div className="challenge-starting-instructions-delivery-date-delay-selection-row">
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay0Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay0Checked
                        }
                      >
                        0 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay1Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay1Checked
                        }
                      >
                        5 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay2Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay2Checked
                        }
                      >
                        15 {t("day")}
                      </div>
                      <input
                        type="checkbox"
                        id="resimli-anket"
                        name="survey-method"
                        checked={
                          isChallengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                        onChange={
                          challengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                      />
                      <div
                        className={`challenge-starting-instructions-delivery-date-delay-selection-checkbox${
                          isChallengeStartingTypeOnlineDeliveryDateDelay3Checked
                            ? " checked"
                            : ""
                        }`}
                        onClick={
                          challengeStartingTypeOnlineDeliveryDateDelay3Checked
                        }
                      >
                        30 {t("day")}
                      </div>
                    </div>
                  )}
                  <div className="challenge-starting-instructions-info-row">
                    <img
                      className="challenge-starting-instructions-info-icon"
                      src={infoIconEmpty}
                      alt="icon"
                    />
                    <div className="challenge-starting-instructions-info">
                      {t("onlineShoppingProcess")}
                    </div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-shopping-date-checkbox-container">
                  <div className="challenge-starting-instructions-delivery-date-checkbox-wrapper">
                    <input
                      type="checkbox"
                      id="resimli-anket"
                      name="survey-method"
                      checked={isChallengeStartingTypeOnlineShoppingDateChecked}
                      onChange={challengeStartingTypeOnlineShoppingDateChecked}
                    />
                    <div
                      className={`challenge-starting-instructions-delivery-date-checkbox${
                        isChallengeStartingTypeOnlineShoppingDateChecked
                          ? " checked"
                          : ""
                      }`}
                      onClick={challengeStartingTypeOnlineShoppingDateChecked}
                    ></div>
                    <div className="challenge-starting-instructions-delivery-date-checkbox-title-wrapper">
                      <label
                        htmlFor="resimli-anket"
                        className="challenge-starting-instructions-delivery-date-checkbox-title"
                      >
                        {t("purchaseDay")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-shopping-date-info-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("onlineShoppingStartDate")}
                  </div>
                </div>
                <div className="challenge-starting-instructions-advice-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("deliveryDaySOTY")}
                  </div>
                </div>
              </div>
              <hr />
              <div className="challenge-starting-instructions-column">
                <div className="challenge-starting-instructions-checkbox-wrapper">
                  <input
                    type="checkbox"
                    id="resimli-anket"
                    name="survey-method"
                    checked={isChallengeStartingTypeStoreChecked}
                    onChange={challengeStartingTypeStoreChecked}
                  />
                  <div
                    className={`challenge-starting-instructions-checkbox${
                      isChallengeStartingTypeStoreChecked ? " checked" : ""
                    }`}
                    onClick={challengeStartingTypeStoreChecked}
                  ></div>
                  <div className="challenge-starting-instructions-checkbox-title-wrapper">
                    <label
                      htmlFor="resimli-anket"
                      className="challenge-starting-instructions-checkbox-title"
                    >
                      {t("store")}
                    </label>
                    <div className="challenge-starting-instructions-checkbox-subtitle"></div>
                  </div>
                </div>
                <div className="challenge-starting-instructions-store-info-row">
                  <img
                    className="challenge-starting-instructions-info-icon"
                    src={infoIconEmpty}
                    alt="icon"
                  />
                  <div className="challenge-starting-instructions-info">
                    {t("inStoreShoppingStartDate")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {returnedChallenge && returnedChallenge.responseData.qr && (
          <QrOptionReadOnly qrCode={returnedChallenge.responseData.qr} />
        )}
        {!returnedChallenge && (
          <QrOption
            qrChecked={(isChecked) => {
              setQrChecked(isChecked);
            }}
            qrString={(theQrString) => {
              setQrString(theQrString);
            }}
          />
        )}

        {/* SOTY COIN */}
        <SingleNumberInput
          title={t("sotyCoin")}
          isMandatory={true}
          titleDescription={t("setSotyCoinAmount")}
          inputPlaceholder={t("enterSotyCoinAmount")}
          infoTextFooter={[
            (coin || 0) +
              " " +
              t("sotyCoin") +
              " " +
              t("equalsTo") +
              " " +
              coin / 10 +
              "TL",
            t("sotyCoinValue"),
          ]}
          value={coin}
          onChange={(e) => {
            handleInputChange(e.target.value, setCoin);
          }}
          onBlur={(e) => {
            coinChange(e);
          }}
        />
        {/* KAMPANYA ONAY DUGMELERI */}
        <div className="challenge-creation-submit-button-container">
          <button
            className="draft"
            onClick={() => {
              handleInteractionUpload(false);
            }}
          >
            {t("saveAsDraft")}
          </button>
          <button
            className="preview"
            onClick={() => {
              handleInteractionUpload(true);
            }}
          >
            {t("preview")}
          </button>
        </div>
      </div>

      <div
        className="challenge-creation-phone-container"
        style={{ position: "sticky", top: "0", right: "30px" }}
      >
        <PhoneViewJoin
          name={challengeName}
          description={challengeDescription}
          prize={coin || 0}
          endDate={endDate || ""}
          logo={challengeImage || noImageToShow}
          imagesUrl={imagesUrl}
          brandLogo={challengeImage || noImageToShow}
          challengeType={t("contentCreation")}
          socialProvider={checkedProvider}
        />
      </div>
    </div>
  );
};

export default ContentCreation;
